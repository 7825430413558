import React from 'react'
import './ContactBanner.css'

function ContactBanner() {
  return (
    <div className='Contact-Banner'>
        <h1 className='head'>
            Contact Us
        </h1>
        <p className='contact-para'>
        Your next step starts here—let’s build something amazing.
        </p>
    </div>
  )
}

export default ContactBanner