import React, { useState } from 'react';
import './GoogleReviews.css';

function GoogleReviews() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const reviews = Array(6).fill({}); // Dummy reviews, replace with actual data

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Determine the number of reviews to show based on screen size
  const isMobile = window.innerWidth <= 768;
  const visibleReviews = isMobile ? 1 : 3; // 1 review on mobile, 3 on desktop

  return (
    <div className="reviews-container">
      <h1 className="reviews-heading">Google Reviews</h1>
      <div className="reviews-carousel-container">
        <button className="arrow left" onClick={handlePrev}>
          &lt;
        </button>
        <div className="reviews-carousel">
          {reviews
            .slice(currentIndex, currentIndex + visibleReviews)
            .map((review, index) => (
              <div key={index} className="review-box">
                <h2 className="review-title">Review {currentIndex + index + 1}</h2>
                <div className="stars">&#9733; &#9733; &#9733; &#9733; &#9734;</div>
              </div>
            ))}
        </div>
        <button className="arrow right" onClick={handleNext}>
          &gt;
        </button>
      </div>
      <p className="reviews-text">
        Read feedback from our satisfied customers or leave your own review to help us improve.
      </p>
      <div className="button-row">
        <button className="review-btn">Read Reviews</button>
        <button className="review-btn">Give a Review</button>
      </div>
    </div>
  );
}

export default GoogleReviews;
