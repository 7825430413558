import React from 'react'
import ContactBanner from '../components/ContactBanner';
import ContactFrom from '../components/ContactFrom';
import Prefooter from '../components/Prefooter';
import Maps from '../components/Maps';
import ContactBoxes from '../components/ContactBoxes'

function Contact() {
  return (
    <div className='Contact'>
        <ContactBanner/>
        <ContactFrom/>
        <Maps/>
        <ContactBoxes/>
        <Prefooter/>
    </div>
  )
}

export default Contact