import React from 'react'
import './PartitionContent.css'
import { FaShieldAlt, FaCubes, FaTools } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './MarbleBanner.css';

function MarbleFloorContent() {
    const images = [
        'Marble (2).jpg',
        'Marble (3).jpg',
        'Marble (4).jpg',
        'Marble (5).jpg',
        'Marble (6).jpg',
        'Marble (7).jpg',
    ];


    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    const updateIndicators = (index) => {
        const indicators = document.querySelectorAll(".slide-counter");
        indicators.forEach((indicator, i) => {
            if (i === index) {
                indicator.style.backgroundColor = "rgb(51, 89, 119)";
                indicator.style.height = "11px";
                indicator.style.width = "11px";
            } else {
                indicator.style.backgroundColor = "white";
                indicator.style.height = "8px";
                indicator.style.width = "8px";
            }
        });
    };

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const bar1 = document.querySelector('.bar-inner-1');
        bar1.style.width = '87%';
        const bar2 = document.querySelector('.bar-inner-2');
        bar2.style.width = '92%';
        const bar3 = document.querySelector('.bar-inner-3');
        bar3.style.width = '95%';
        const bar4 = document.querySelector('.bar-inner-4');
        bar4.style.width = '90%';
    }

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 500);
        }
    }, [inView])

    useEffect(() => {
        updateIndicators(currentIndex);
    }, [currentIndex]);

  return (
    <div className='PartitionContent'>
        <div className="MarbleBanner">
                <div className='partition-banner-inner'>
                    <h1 className='partition-head'>
                        Marble Flooring
                    </h1>
                </div>
            </div>
            <div className='partition-container'>
                <div className='partition-top'>
                    <h1 className='partition-head-1'>
                        Gypsum Partition Services: Your Space, Your Way
                    </h1>
                    <p className='partition-para'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium provident quae quidem culpa dicta id sapiente ullam facilis mollitia laboriosam, rem error velit! Molestiae a quos earum quas enim voluptates? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae temporibus nam hic eaque odio. Consequuntur minus enim, velit officia ad laborum quaerat ipsa mollitia neque possimus, quasi eum necessitatibus magni?
                    </p>
                </div>
                <div className='slider' >
                    <button className='slider-button prev' onClick={prevSlide}>
                        &#8249;
                    </button>
                    <div className='slider-images'>
                        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
                            <div key={index} className='slider-image'>
                                <img src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </div>
                    <button className='slider-button next' onClick={nextSlide}>
                        &#8250;
                    </button>
                </div>
                <div className="slider-indicators">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`slide-counter`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='partition-content-top'>
                <h1 className='partition-head-2'>
                    What is Gypsum Partition
                </h1>
                <p className='partition-para'>
                    Transform your interiors with expertly designed and professionally installed Ypsum partitions that are tailored to meet the unique needs of your space. Whether you’re looking to maximize the functionality of your office, create personalized living areas in your home, or improve the acoustics and thermal insulation of any room, our Ypsum partition solutions offer versatility and efficiency.

                    In modern homes and businesses, the ability to divide spaces effectively while maintaining an open, airy feel is essential. Our Ypsum partitions provide a seamless blend of practicality and aesthetic appeal. With a range of design options, you can choose a partition style that not only fits your spatial requirements but also complements your interior décor. Whether you need to create private workspaces in a commercial setting, divide a large living area into cozy zones, or insulate your rooms for better temperature control and noise reduction, our team ensures that every installation is done with precision and care.

                    Our high-quality Ypsum partitions offer durability, stability, and a long-lasting solution that enhances both the functionality and visual appeal of your space.
                </p>
                <h1 className='partition-head-2'>
                    Benefits of using Gypsum Partition
                </h1>
                <ul>
                    <li>Lightweight and Strong: Easy to install yet robust enough for daily use.</li>
                    <li>Enhanced Aesthetics: Smooth finishes perfect for painting or wallpapering.</li>
                    <li>Quick Installation: Fast turnaround to minimize disruption to your routine.</li>
                    <li>Soundproofing and Insulation: Excellent acoustic and thermal properties.</li>
                </ul>
            </div>

            <div className='content-container'>
                <div className='current-section'>
                    <FaShieldAlt color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Durable Gypsum Walls
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaCubes color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Elegant Design
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
                <div className='current-section'>
                    <FaTools color='rgb(13, 51, 66)' size={70} />
                    <h3>
                        Easy Installation
                    </h3>
                    <p className='section-para'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea quisquam, id eveniet voluptatem a dolorum..</p>
                    <button className='read-more'>
                        Learn More
                    </button>
                </div>
            </div>
            <div className='partition-content-bottom' >
                <div className='content-bottom-left'>
                    <h1 className='partition-head-2'>
                        Don't You Want Such a <br></br> Beautiful Design?
                    </h1>
                    <p className='partition-para-bottom'>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente assumenda enim magni numquam harum et odio fuga eligendi aut minus, consequuntur maiores omnis laboriosam dignissimos ea rerum. Velit praesentium, error nemo aut quibusdam, et vitae, nisi expedita illo dolorem officiis?
                    </p>
                    <button className='read-more'>
                        Contact Now
                    </button>
                    <div className='progressbar-container' ref={ref}>
                        <div className='progress-bars'>
                            <div>
                                <h3 className='bar-head'>
                                    Expertise & Excellence
                                </h3>
                                <p className='bar-para'>
                                    Delivering quality in gypsum partitions, ensuring durable and stunning interior designs.
                                </p>
                                <div className='bar'>
                                    <div className='bar-inner-1'>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='progress-bars'>
                            <div>
                                <h3 className='bar-head'>
                                    Customer Service
                                </h3>
                                <p className='bar-para'>
                                    Our team is dedicated to providing responsive and reliable service tailored to your needs.
                                </p>
                                <div className='bar'>
                                    <div className='bar-inner-2'>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='progress-bars'>
                            <div>
                                <h3 className='bar-head'>
                                    Service Structure
                                </h3>
                                <p className='bar-para'>
                                    Our gypsum partitions are designed with precision, blending elegance with practical functionality.
                                </p>
                                <div className='bar'>
                                    <div className='bar-inner-3'>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='progress-bars'>
                            <div>
                                <h3 className='bar-head'>
                                    Customer's Satisfaction
                                </h3>
                                <p className='bar-para'>
                                    Your satisfaction drives us to exceed expectations with every project we undertake.
                                </p>
                                <div className='bar'>
                                    <div className='bar-inner-4'>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content-bottom-next'>
                    <img src='Marble (8).jpg' className='content-partiton-im' />
                </div>
            </div>
    </div>
  )
}

export default MarbleFloorContent