import React from 'react';
import './AboutBanner.css';

function AboutBanner() {
  return (
    <div className='AboutBanner-1'>
        <h1 className='head'>
            About Us
        </h1>
    </div>
  )
}

export default AboutBanner