import React from 'react'
import AboutBanner from '../components/AboutBanner'
import Prefooter from '../components/Prefooter'
import AboutContainer from '../components/AboutContainer'
import AboutContent from '../components/AboutContent'
import AboutContainer1 from '../components/AboutContainer1'
import Analytics from '../components/Analytics'

function About() {
  return (
    <div>
      <AboutBanner/>
      <AboutContent/>
      <AboutContainer/>
      <AboutContainer1/>
      <Analytics/>
      <Prefooter/>
    </div>
  )
}

export default About