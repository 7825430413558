import React from 'react'
import MarbleFloorContent from '../components/MarbleFloorContent'
import Prefooter from '../components/Prefooter'

function MarbleFloor() {
  return (
    <div>
        <MarbleFloorContent/>
        <Prefooter/>
    </div>
  )
}

export default MarbleFloor