import React, { useEffect } from 'react';
import './ContactBoxes.css';
import { FaPhone, FaMailBulk } from 'react-icons/fa';
import { FaLocationPin } from 'react-icons/fa6';
import { useInView } from 'react-intersection-observer';

function ContactBoxes() {
    const [ref, inView] = useInView({
        threshold: 0.2,
    });

    function handleEffect() {
        const element = document.querySelectorAll('#contact-box-1');
        if (element) {
            element.forEach(elements => {
                elements.style.transform = "translateY(-50px)"
            });
        }
    }

    useEffect(() => {
        if (inView) {
            const timeoutId = setTimeout(() => {
                handleEffect();
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [inView]);

    return (
        <div className="ContactBoxes" ref={ref}>
            <div className="contact-inner">
                <div className="contact-box-1" id='contact-box-1'>
                    <p className="copy-text">Copy Phone Number</p>
                    <FaPhone id="icon-1" className="contact-box-icons" color="white" size={50} />
                </div>
                <div className="contact-box-1" id='contact-box-1'>
                    <p className="copy-text">Copy Address</p>
                    <FaLocationPin className="contact-box-icons" color="white" size={50} />
                </div>
                <div className="contact-box-1" id='contact-box-1'>
                    <p className="copy-text">Copy Email</p>
                    <FaMailBulk className="contact-box-icons" color="white" size={50} />
                </div>
            </div>
        </div>
    );
}

export default ContactBoxes;
