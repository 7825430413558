import React, { useEffect } from 'react';
import './Services.css';
import { useInView } from 'react-intersection-observer';

function Services() {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const handleEffect = () => {
        const elements = document.querySelectorAll('#current-service-id');
        elements.forEach((el) => {
            el.style.opacity = 1;
            el.style.marginTop = "-10px";
        });
    };

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                handleEffect();
            }, 100);
        }
    }, [inView]);

    return (
        <div className='Services' id='services' ref={ref}>
            <h1 className='services-head'>
                Our Services
            </h1>
            <div className='all-services'>
                <div className='current-service' id='current-service-id'>
                    <img src='011.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Gypsum Partition
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='0000.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Artificial Grass
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='tiles.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Tile Fixing
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='e4.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Eletrician Services
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
            </div>
            <div className='all-services'>
                <div className='current-service' id='current-service-id'>
                    <img src='paint.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Paint Services
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='marble.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Marble Flooring
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='c1.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        False Ceiling
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
                <div className='current-service' id='current-service-id'>
                    <img src='c4.jpg' className='im-service-1' />
                    <h2 className='service-1-head'>
                        Gypsum Ceiling
                    </h2>
                    <p className='service-text'>
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software.
                    </p>
                    <button className='read-more'>
                        read more
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Services;
