import React from 'react';
import './Maps.css';

function Maps() {
  return (
    <div className="maps-container">
      <div className="maps-next">
        <iframe
          className="map-location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.6110315663857!2d55.319730515003774!3d25.28152363585171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c98a1eaf50f%3A0x7d2d40258fa20469!2sND%20Gypsum%20%26%20Partition%20Services!5e0!3m2!1sen!2sae!4v1725606505746!5m2!1sen!2sae"
          allowFullScreen=""
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Maps;
