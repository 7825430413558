import React, { useState, useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import './Analytics.css';

function Analytics() {
    const data = [
        { label: 'Current Orders', value: 150 },
        { label: 'Cities Served', value: 40 },
        { label: 'Homes Served', value: 1000 },
        { label: 'Happy Clients', value: 500 }
    ];

    const Counter = ({ value, inView }) => {
        const [count, setCount] = useState(0);

        useEffect(() => {
            if (inView) {
                setTimeout(() => {
                    let start = 0;
                    const increment = value / 30;
                    const interval = setInterval(() => {
                        start = Math.min(start + increment, value);
                        setCount(Math.floor(start));
                        if (start >= value) clearInterval(interval);
                        return () => clearInterval(interval);
                    }, 20);
                }, 500);
            }
        }, [inView, value]);

        return <>{count}+</>;
    };

    return (
        <div className='Analytics'>
            {data.map((item, index) => (
                <InView key={index} triggerOnce>
                    {({ inView, ref }) => (
                        <div className='analytics-container' ref={ref}>
                            <h1 className='analytics-value'>
                                <Counter value={item.value} inView={inView} />
                            </h1>
                            <p className='analytics-text'>{item.label}</p>
                            <hr />
                        </div>
                    )}
                </InView>
            ))}
        </div>
    );
}

export default Analytics;
