import React from 'react'
import Prefooter from '../components/Prefooter'
import TileContent from '../components/TileContent'

function Tile() {
  return (
    <div>
      <TileContent/>
      <Prefooter/>
    </div>
  )
}

export default Tile