import React from 'react'
import ElectricalContent from '../components/ElectricalContent'
import Prefooter from '../components/Prefooter'

function Electrical() {
  return (
    <div>
        <ElectricalContent/>
        <Prefooter/>
    </div>
  )
}

export default Electrical