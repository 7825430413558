import React from 'react'
import Prefooter from '../components/Prefooter'
import PaintContent from '../components/PaintContent'

function Paint() {
  return (
    <div id="painting">
        <PaintContent/>
        <Prefooter/>
    </div>
  )
}

export default Paint