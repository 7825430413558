import React from 'react'
import CeilingContent from '../components/CeilingContent'
import Prefooter from '../components/Prefooter'

function Ceiling() {
  return (
    <div>
        <CeilingContent/>
        <Prefooter/>
    </div>
  )
}

export default Ceiling