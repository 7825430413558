import React from 'react'
import './AboutContainer.css'

function AboutContainer() {
  return (
    <div className='AboutContainer'>
      <div className='about-container-left'>
        <img src='abt.jpg' alt='?' className='im-about' />
      </div>
      <div className='about-container-next'>
        <div className='text-line-1'>
          <p>
            WELCOME TO ND SERVICES
          </p>
        </div>
        <h1 className='about-head'>
          Since We <br>
          </br> Started Work in <br>
          </br> 1980
        </h1>
        <p className='about-para'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          <br></br> <br></br>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </p>
      </div>
    </div>
  )
}

export default AboutContainer