import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp, FaPhone, FaChevronDown, FaPaintBrush, FaHammer, FaWrench, FaTshirt, FaTools, FaPlug, FaSearch, FaBars } from 'react-icons/fa';
import './Navbar.css';
import { Link } from 'react-router-dom';

function Navbar() {
    const [activeLink, setActiveLink] = useState('home');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleNavClick = (section) => {
        setActiveLink(section);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    return (
        <div className='Navbar'>
            <div className='top-bar'>
                <div className='top-bar-left'>
                    <p className='top-bar-text'>Contact us via email: info@ndservices.com</p>
                </div>
                <div className='top-bar-next'>
                    <FaFacebook color="white" size={20} />
                    <FaInstagram color="white" size={20} />
                    <FaTwitter color="white" size={20} />
                    <FaWhatsapp color="white" size={20} />
                </div>
            </div>

            <div className='bottom-bar'>
                <div className='icon-searchbar'>
                    <img className='icon' src='icon.png' alt='Logo' />
                    <input className='searchbar'
                        id="search-input"
                        type="text"
                        placeholder="Search..."
                    />
                    <button className='search-btn'>
                        <FaSearch className='search-icon' />
                    </button>
                </div>

                <div className='nav-links'>
                    {[ 
                        { label: 'HOME', to: '/' },
                        { label: 'CONTACT', to: '/contact' },
                        { label: 'GYPSUM PARTITION', to: '/partition' },
                        { label: 'MARBLE FLOORS', to: '/marble' },
                        { label: 'GYPSUM CEILING', to: '/ceiling' },
                        { label: 'ARTIFICIAL GRASS', to: '/Grass' },
                    ].map(({ label, to }) => (
                        <Link
                            key={to}
                            to={to}
                            className={activeLink === to ? 'active' : ''}
                            onClick={() => handleNavClick(to)}
                        >
                            {label}
                        </Link>
                    ))}

                    <div className='services-dropdown'>
                        <a
                            href='#'
                            className={activeLink === 'services' ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault();
                                toggleDropdown();
                            }}
                        >
                            SERVICES
                            <FaChevronDown className='dropdown-icon-1' />
                        </a>
                        {dropdownOpen && (
                            <div className='dropdown-menu'>
                                <div className='dropdown-row'>
                                    <div className='dropdown-column'>
                                        <Link
                                            to="/electrical"
                                            onClick={() => { handleNavClick('electrical'); setDropdownOpen(false); }}
                                        >
                                            <FaPlug className='dropdown-icon' />
                                            Electrician Services
                                        </Link>
                                        <Link
                                            to="/partition"
                                            onClick={() => { handleNavClick('partition'); setDropdownOpen(false); }}
                                        >
                                            <FaHammer className='dropdown-icon' />
                                            Gypsum Partition
                                        </Link>
                                        <Link
                                            to="/ceiling"
                                            onClick={() => { handleNavClick('fixing'); setDropdownOpen(false); }}
                                        >
                                            <FaWrench className='dropdown-icon' />
                                            Gypsum Ceiling
                                        </Link>
                                        <Link
                                            to="/marble"
                                            onClick={() => { handleNavClick('cleaning'); setDropdownOpen(false); }}
                                        >
                                            <FaTshirt className='dropdown-icon' />
                                            Marble Flooring
                                        </Link>
                                        <Link
                                            to="/grass"
                                            onClick={() => { handleNavClick('plumbing'); setDropdownOpen(false); }}
                                        >
                                            <FaTools className='dropdown-icon' />
                                            Artificial Grass
                                        </Link>
                                        <Link
                                            to="/tile"
                                            onClick={() => { handleNavClick('plumbing'); setDropdownOpen(false); }}
                                        >
                                            <FaTools className='dropdown-icon' />
                                            Tile Fixing
                                        </Link>
                                        <Link
                                            to="/paint"
                                            onClick={() => { handleNavClick('painting'); setDropdownOpen(false); }}
                                        >
                                            <FaPaintBrush className="dropdown-icon" />
                                            Painting Services
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Mobile Menu Icon */}
                <div className='menu-icon' onClick={toggleMobileMenu}>
                    <FaBars color="rgb(20, 73, 134)" size={30} />
                </div>

                {/* Contact Button (hidden on mobile) */}
                <div className='contact-button'>
                    <a href="#contact" className='contact-btn'>
                        Get in Touch
                        <FaPhone className='phone-icon' />
                    </a>
                </div>
            </div>

            {/* Mobile Menu Dropdown */}
            {mobileMenuOpen && (
                <div className='mobile-menu'>
                    {[ 
                        { label: 'HOME', to: '/' },
                        { label: 'ABOUT', to: '/about' },
                        { label: 'CONTACT', to: '/contact' },
                        { label: 'GYPSUM PARTITION', to: '/partition' },
                        { label: 'MARBLE FLOORS', to: '/marble' },
                        { label: 'GYPSUM CEILING', to: '/ceiling' },
                        { label: 'ARTIFICIAL GRASS', to: '/Grass' },
                    ].map(({ label, to }) => (
                        <Link
                            key={to}
                            to={to}
                            className={activeLink === to ? 'active' : ''}
                            onClick={() => handleNavClick(to)}
                        >
                            {label}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Navbar;
