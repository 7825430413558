import React from 'react'
import './AboutContainer1.css'

function AboutContainer1() {
    return (
        <div className='AboutContainer1'>
            <div className='about-container1-left'>
                <h1 className='about-container-head-1'>
                    Best Interior <br></br> Design Company in <br></br> Dubai
                </h1>
                <p className='about-bottom-text'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, quos dolorum. Sit, id eaque, repellendus quod nisi corporis cumque dicta quae perferendis culpa quasi exercitationem! Enim quae vitae deleniti veniam odio? Eveniet vero accusantium nemo dolores delectus, amet tenetur, aperiam eaque iusto blanditiis hic. Laborum ipsum accusamus labore aut pariatur?
                    <br></br> <br></br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores nostrum temporibus sed assumenda modi amet corporis aliquam sunt nulla illo repudiandae vitae recusandae cum ipsum iste et, maxime vero repellendus!
                </p>
            </div>
            <div className='about-container1-next'>
                <img src='abt2.jpg' alt='?' className='im-about-1' />
            </div>
        </div>
    )
}

export default AboutContainer1