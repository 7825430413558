import React from 'react'
import GrassContent from '../components/GrassContent'
import Prefooter from '../components/Prefooter'

function Grass() {
  return (
    <div>
        <GrassContent/>
        <Prefooter/>
    </div>
  )
}

export default Grass