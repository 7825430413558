import React from 'react'
import PartitionBanner from '../components/PartitionBanner'
import PartitionContainer from '../components/PartitionContainer'
import PartitionContent from '../components/PartitionContent'
import Prefooter from '../components/Prefooter'
import '../index.css';
import App from '../App';

function Partition() {
  return (
    <div>
        <PartitionContent/>
        <Prefooter/>
    </div>
  )
}

export default Partition