import React from 'react';
import './Banner.css';

function Banner() {
    return (
        <div className='Banner'>
            <div className='banner-box'>
                <div className='line-text'>
                    <hr className='line' />
                    <p className='text'>TRUST AND RECOMMEND</p>
                </div>
                <h1 className='head'>
                    Exceptional <br /> designing for <br /> exceptional Spaces
                </h1>
                <button className='read-more'>Read More</button>
            </div>
        </div>
    );
}

export default Banner;
