import React from 'react'
import './AboutContent.css'

function AboutContent() {
  return (
    <div className='AboutContent'>
        <h1 className='about-head-1'>
            Why Choose Us?
        </h1>
        <p className='about-para-1'>
            <b>Lorem ipsum</b>  dolor sit, amet consectetur adipisicing elit. Dolores rem cumque debitis magnam tempora voluptate eum vel, mollitia, est exercitationem iure voluptatum quidem nostrum eligendi consequatur temporibus, quaerat minus.
            <br></br> Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit neque autem amet exercitationem veniam eos debitis saepe, ab nulla vitae ipsa, omnis itaque incidunt voluptate sunt tempore rerum minus nemo nobis quia nesciunt enim quod? Ipsam blanditiis aperiam aliquid at. Optio, vel temporibus?
        </p>
    </div>
  )
}

export default AboutContent